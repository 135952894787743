<template>
  <div>
    <div class="text-center mb-5">
      <template v-if="result == 1">
        <img src="/img/sun.png" />
        <p>特に問題は認められませんでした。</p>
      </template>
      <template v-if="result == 2">
        <img src="/img/rain-cloud.png" />
        <p>今日は少し脳がお疲れのようです。</p>
      </template>
      <template v-if="result == 3">
        <img src="/img/rain-cloud.png" />
        <p>今日は少し脳がお疲れのようです。</p>
      </template>
      <img v-if="error" src="/img/error.png" />
    </div>

    <div class="text-center">
      <record-progress v-if="recording" :progress="progress" size="30vh" />
      <img v-else :src="result > 0 ? '/img/re-record.png' : '/img/record.png'" @click="record" />
    </div>
  </div>
</template>

<style scoped>
img {
  width: 30vh;
  cursor: pointer;
}

p {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Meiryo", "Segoe UI", "Arial", sans-serif;
}
</style>

<style>
.main {
  height: 100vh;
}

.sun {
  background: linear-gradient(to bottom, #bbeaff, #fff);
}

.rain-cloud {
  background: linear-gradient(to bottom, #ffc2c8, #fff);
}
</style>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import { getSubject, postSubjectVoice } from "@/common/api";
import { convertToWAV } from "@/common/wav";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import RecordProgress from "@/components/RecordProgress.vue";

export default {
  components: {
    RecordProgress,
  },
  layout: "staff",
  setup() {
    const route = useRoute();
    const recording = ref(false);
    const result = ref(0);
    const error = ref(false);
    const progress = ref(0);

    watch(() => result.value, (r) => {
      if (r == 1) document.body.className = 'sun';
      else if (r == 2) document.body.className = 'rain-cloud';
      else if (r == 3) document.body.className = 'rain-cloud';
      else document.body.className = '';
    }, { immediate: true });

    onMounted(() => {
      console.log(document.querySelector('.topbar'));
      document.querySelector('.topbar').style.setProperty('display', 'none', 'important');
    });

    onBeforeUnmount(() => {
      document.body.className = '';
      document.querySelector('.topbar').removeProperty('display');
    });

    async function checkVoiceResult() {
      let data = await getSubject(route.params.id);
      if (data.subject?.voice) {
        if (data.subject.voice.result > 0) {
          result.value = data.subject.voice.result;
          recording.value = false;
          return;
        }
      }

      setTimeout(checkVoiceResult, 5000);
    }

    async function record() {
      progress.value = 0;
      recording.value = true;
      result.value = 0;

      let mediaRecorder;
      let recordedChunks = [];
      let start = 0;

      function checkProgress() {
        let ts = (new Date()).valueOf();
        console.log(ts, start);

        if (ts - start > 30000) {
          mediaRecorder.stop();
          return;
        }

        progress.value = ((ts - start) / 32000.0) * 100;
        console.log(progress.value);
        setTimeout(checkProgress, 500);
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
                recordedChunks.push(event.data);
            }
        };

        mediaRecorder.onstop = async () => {
          const audioContext = new AudioContext();
          const blob = convertToWAV(recordedChunks, audioContext.sampleRate);

          // サーバーにアップロード
          const formData = new FormData();
          formData.append('file', blob, 'sound.wav');
          formData.append("recorded_at", dayjs().utc().format("YYYY-MM-DD HH:mm"));

          try {
            await postSubjectVoice(formData, route.params.id);
          } catch (error) {
            console.error(error);
            alert('録音データの分析にエラーが発生しました');
            error.value = true;
            recording.value = false;
          }

          checkVoiceResult();
        };

        mediaRecorder.start();

        // 30秒後に録音を自動停止
        start = (new Date()).valueOf();
        setTimeout(checkProgress, 500);
      } catch (error) {
        console.error(error);
        alert('マイクへのアクセスが拒否されました、またはサポートされていません。');
        error.value = true;
      }
    }

    return {
      recording, result, error, progress,
      record,
    };
  }
}
</script>