<template>
  <div class="box" :style="{ '--size': size }">
      <div class="percent">
          <svg viewBox="0 0 640 640">
              <circle class="base" cx="320" cy="320" r="290"></circle>
              <circle class="line" cx="320" cy="320" r="290" :style="lineStyle"></circle>
              <defs>
                <linearGradient id="Gradient1">
                    <stop class="stop1" offset="0%" />
                    <stop class="stop2" offset="25%" />
                    <stop class="stop3" offset="50%" />
                    <stop class="stop4" offset="75%" />
                    <stop class="stop5" offset="100%" />
                </linearGradient>
            </defs>
          </svg>
      </div>
  </div>
  <p><span>{{ Math.floor(progress) }}%</span><br />音声を解析中</p>
</template>

<style scoped>
p {
  color: rgb(219, 142, 255);
  font-size: 1.35rem;
  line-height: 2rem;
  font-weight: bold;
}

p span {
  font-size: 1.25rem;
}

.box {
  position: relative;
  margin: 5rem auto;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  margin-bottom: 3rem;
}

.box::before {
  display: block;
  content: "";
  width: calc(var(--size) * 0.8125);
  height: calc(var(--size) * 0.8125);
  background: url(../assets/spectrum.gif) no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.percent {
  position: relative;
  width: var(--size);
  height: var(--size);
}

svg {
  position: relative;
  width: var(--size);
  height: var(--size);
  transform: rotate(-90deg);
}

svg circle {
  position: relative;
  fill: none;
  stroke-width: 50;
  stroke: #f3f3f3;
  stroke-dasharray: 1821;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}


svg .line {
  stroke: url(#Gradient1);
}

.stop1 {
  stop-color: rgb(154, 236, 255);
}

.stop2 {
  stop-color: rgb(152, 184, 255);
}

.stop3 {
  stop-color: rgb(219, 142, 255);
}

.stop4 {
  stop-color: rgb(223, 155, 255);
}

.stop5 {
  stop-color: rgb(247, 182, 255);
}
</style>

<script>
import { computed } from "vue";

export default {
  props: ["progress", "size"],
  setup(props) {
    const lineStyle = computed(() => {
      return { 'stroke-dashoffset': 1821 - (1821 * props.progress) / 100 };
    });

    return { lineStyle };
  }
}
</script>